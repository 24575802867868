/* eslint-disable no-unused-vars */
import React from "react";
import UserProfile from "../../../../app/partials/layout/UserProfile";
import { Dropdown } from "react-bootstrap"

export default class Topbar extends React.Component {
  render() {
    return (
      <div className="kt-header__topbar">
        <div className="kt-header__brand-nav">
          {/* <Dropdown drop="down">
            <Dropdown.Toggle
              style={{borderColor: '#000000'}}
              variant="transparent"
              className="btn btn-default dropdown-toggle"
              id="dropdown-toggle-top"
            >
              Quick Access
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-md">
              <ul className="kt-nav kt-nav--bold kt-nav--md-space">
                <li className="kt-nav__item">
                  <a className="kt-nav__link active" href="/home">
                    <span className="kt-nav__link-icon">
                      <i className="flaticon-home-2" />
                    </span>
                    <span className="kt-nav__link-text">Home</span>
                  </a>
                </li>
                <li className="kt-nav__item">
                  <a className="kt-nav__link" href="/trainings">
                    <span className="kt-nav__link-icon">
                      <i className="flaticon2-map" />
                    </span>
                    <span className="kt-nav__link-text">Trainings</span>
                  </a>
                </li>
                <li className="kt-nav__item">
                  <a className="kt-nav__link" href="/rates">
                    <span className="kt-nav__link-icon">
                      <i className="flaticon2-list" />
                    </span>
                    <span className="kt-nav__link-text">Consult Rates</span>
                  </a>
                </li>
                <li className="kt-nav__item">
                  <a className="kt-nav__link" href="/earnings">
                    <span className="kt-nav__link-icon">
                      <i className="flaticon2-analytics" />
                    </span>
                    <span className="kt-nav__link-text">Earnings</span>
                  </a>
                </li>
                <li className="kt-nav__separator" />
              </ul>
            </Dropdown.Menu>
          </Dropdown> */}
  
        </div>
        <div style={{ paddingLeft: "1rem" }}>
        <div className="kt-login__copyright">
                    &copy; {"2022"} Rocket Trend
                  </div>
          {/* <UserProfile showAvatar={false} showHi={true} showBadge={true} /> */}
        </div>
      </div>
    );
  }
}
