export default {
  header: {
    self: {},
    items: [
      {
        title: "Home",
        root: true,
        alignment: "left",
        page: "home",
        icon: "flaticon-star"
      },
      {
        title: "Products",
        root: true,
        alignment: "left",
        toggle: "click",
        icon: "flaticon2-open-text-book",
        page: "products"
      },
    ]
  }
};
