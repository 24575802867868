import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';


import './stocks.css'
import dotenv from 'dotenv'

const BASE_URL = `${process.env.REACT_APP_API_URL}`

export default function CryptoCharts() {
    const [positions, setPositions] = useState([])



    const columns = [
        { field: 'id', headerName: 'id', width: 90 },
        { field: 'name', headerName: 'name', width: 150 },
        { field: 'symbol', headerName: 'symbol', width: 90 },
        {
            field: 'qty',
            headerName: 'Quantity',
            width: 150,
            type: 'number',
            editable: false,
        },
        {
            field: 'current_price',
            headerName: 'Current Price',
            width: 150,
            type: 'number',
            editable: false,
        },
        {
            field: 'percent_change',
            headerName: 'Percent Change',
            width: 110,
            type: 'number',
            sortable: true,
            editable: false,
        },
        {
            field: 'price_change',
            headerName: 'Price Change',
            width: 110,
            sortable: true,
            editable: false,
        },
        {
            field: 'forecast_price',
            headerName: 'Forecast Price',
            width: 110,
            editable: false,
        },
        {
            field: 'average_volume',
            headerName: 'Average Volume',
            width: 110,
            type: 'number',
            editable: false,
        },
        {
            field: 'total_cost',
            headerName: 'Total Cost',
            width: 110,
            type: 'number',
            editable: false,
        },
        {
            field: 'forecast_market_value',
            headerName: 'Forecast Market Value',
            width: 110,
            type: 'number',
            sortable: true,
            editable: false,
        },
        {
            field: 'forecast_market_gains',
            headerName: 'Forecast Market Gains',
            width: 110,
            type: 'number',
            sortable: true,
            editable: false,
        },
        {
            field: 'start_forecast_date',
            headerName: 'Start Date',
            width: 110,
            sortable: true,
            editable: false,
        },
        {
            field: 'end_forecast_date',
            headerName: 'End Date',
            width: 110,
            sortable: true,
            editable: false,
        },
    ];



    useEffect(() => {
        (async () => {
            const url = `${BASE_URL}/api/chart/cryptos`;
            let response = await fetch(url);
            let data = await response.json();

            let pos = data.map((x, index) => {
                x.id = index;
                return x
            })
            setPositions(pos)
        })()
    }, [])

    return (
        <div>
            <div>
                {positions.length !== 0 ?
                    <>
                        <div style={{ height: 400, width: '100%' }}>
                            <DataGrid initialState={{
                                sorting: {
                                    sortModel: [{ field: 'forecast_market_gains', sort: 'desc' }],
                                },
                            }} rows={positions} columns={columns} />

                        </div>
                    </>
                    : ''}
            </div>

        </div>

    )
}