import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import * as builder from "../ducks/builder";

function KtContent({ children, contentContainerClasses }) {
  let location = useLocation();
  const ref = useRef();
  useEffect(() => {
    if (!ref && !ref.current) {
      return;
    }

    ref.current.classList.remove("kt-grid--animateContent-finished");
    setTimeout(() => {
      ref.current.classList.add("kt-grid--animateContent-finished");
    }, 1);
  }, [location]);

  return (
    <div
      ref={ref}
      className={`kt-grid--animateContent kt-container ${contentContainerClasses} kt-grid__item kt-grid__item--fluid kt-grid--animateContent-finished`}
    >
      {children}
      <div style={{ paddingLeft: 0, paddingRight: 0, textAlign: 'center' }} >
        <form action="https://www.paypal.com/donate" method="post" target="_top">
          <input type="hidden" name="hosted_button_id" value="J7KU95MH45LUG" />
          <input type="image" src="https://pics.paypal.com/00/s/NmU5YzY1OTQtNTM0ZC00NjViLWJiOTMtYTMwNTg2ZGQ5NjVk/file.PNG" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
          <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
        </form>
      </div>
    </div>
  );
}

const mapStateToProps = store => ({
  contentContainerClasses: builder.selectors.getClasses(store, {
    path: "content_container",
    toString: true
  })
});

export default connect(mapStateToProps)(KtContent);
