import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/styles';
import { LineChart, Line, Tooltip, XAxis, YAxis, ResponsiveContainer, ReferenceLine, Label } from 'recharts';
import momentBusinessDays from 'moment-business-days'
import moment from 'moment';
import './stocks.css';

const BASE_URL = `${process.env.REACT_APP_API_URL}`

export default function Chart(value) {
  const theme = useTheme();
  const [data, setData] = useState([])
  const [forecast, setForecast] = useState([])
  const [props, setProps] = useState({})
  const [score, setScore] = useState(0)
  const [average, setAverage] = useState(0)
  const [avgclr, setAvgclr] = useState('#fff')
  const [stock, setStock] = useState({})
  const [priceLowRange, setLowPriceRange] = useState(0)
  const [priceHighRange, setHighRange] = useState(0)

  async function getApi(url) {

    return await fetch(url, {
        method: "GET",
    })
        .then(response => response.json())
        .catch(error => console.log(error))

}

  useEffect(() => {
    (async () => {
        const url = `${BASE_URL}/api/watchlist/symbol/${value.symbol}`;

        let data = await getApi(url)
        setProps({
          stock: data[0].current_price,
          data: data[0].chart_data,
          average: data[0].chart_data?.average,
          score: data[0].chart_data?.score
        })
  
    })()
}, [value])


  useEffect(() => {

    if(props && props.data && props.data.length !== 0){
      console.log({price: props})
      setStock(props.stock)
      setAverage(props.average)
      setScore(props.score)
      setStock(props.stock)
      
    
      let hist = props.data.history.map(x => {
      let h = {history: x.close, date: x.date}
      return h
     })
     //        

     let cast = props.data.forecast.map(x => {
      let h = {history: x.close, date: x.date}
      return h
     })

     let histMin = Math.min(...hist)
     let histMax = Math.max(...hist)
     let castMin = Math.min(...cast)
     let castMax = Math.max(...cast)

     let rangeofPrices = [histMin,histMax,castMin,castMax]

     setLowPriceRange(Math.min(...rangeofPrices))

     setHighRange(Math.max(...rangeofPrices))
   
      // let cast = props.data.forecast.map((x, index) => {
      //   let new_date = momentBusinessDays(hist[hist.length - 1].date, 'MM/DD/YY').businessAdd(index + 1)._d 
      //   console.log(new_date)
      //   return {
      //     history: x,
      //     date: moment(new_date).format('MM/DD/YY')
      //   }
      // })
      console.log(cast)

      let history = hist
      let bb = props.average > props.data.history[props.data.history.length -1].close ? '#82ca9d' : '#CF6679'
      setAvgclr(bb)
      setForecast(cast)

      setData(history)
    }
  }, [props]);

  return (
    <>
    <React.Fragment>
      {forecast.length ? 

      <ResponsiveContainer width={'100%'} height={'80%'} aspect={1}  style={{color: '#82ca9d', display: 'flex', grow: 1, margin: 0}}>
        <LineChart
        
        data={data}
          wrapperStyle={{ position: 'relative' }}
        >

          <XAxis dataKey="date" stroke={'#fff'}  allowDuplicatedCategory={false} style={{color: '#82ca9d'}} >

            </XAxis>
       
         
          <YAxis stroke={'#fff'} allowDataOverflow type={'number'} domain={[priceLowRange, priceHighRange]}>
            {/* <Label>
            {props.stock.current_price.price}
            </Label> */}         

          </YAxis>
         {/* <Tooltip /> */}
       
          <Line type="monotone"  data={data} dataKey="history" stroke={'#BB86FC'} dot={false} key={'history'} name={'History'}  style={{strokeWidth: '5px'}}/>

          <Line type="monotone" dataKey="history" data={forecast} name={'ForeCast Data'} key={'Forecast'} fill={avgclr} stroke={avgclr}  dot={false}
          style={{strokeWidth: '5px', color:'#fff'}} />
          
          <ReferenceLine y={forecast[forecast.length -1].history} label={"Forecast Price:" + " " + forecast[forecast.length -1].history} stroke={avgclr} strokeDasharray="3 3" style={{color: avgclr}} />

          <ReferenceLine y={data[data.length -1].history} label={"Current Price:" + " " + data[data.length -1].history} stroke="#BB86FC" strokeDasharray="3 3" style={{color: avgclr}} />

   
        </LineChart>
      </ResponsiveContainer>
      : ''}
    </React.Fragment>
    </>
  );
}