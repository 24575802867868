import axios from "axios";
import dotenv from 'dotenv'
dotenv.config()

export const BASE_URL = `${process.env.REACT_APP_API_URL}`

export async function login(email, password) {
  return axios.post(BASE_URL+'/auth/login', {
    email,
    password
  });
}

export async function register(
  email,
  fullname,
  password,
) {
  return await axios.post(BASE_URL + "/auth/register", {
    email: email,
    fullname,
    password,
  });
}

export function requestPassword(email) {
  return axios.post(BASE_URL + "/auth/users/forgot-password", { email });
}

export function getUserByToken(jwt) {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(BASE_URL +`/auth/current`, {
    headers: { Authorization: jwt }
  });
}
