import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Pagination, PaginationItem, Box, Button, Card, CardContent, Avatar, Typography, CardHeader, Table, TableBody, TableCell, TableHead, TableRow, Paper, TableContainer, CardMedia } from '@mui/material'
import Chart from './Charts'
import './stocks.css'
import { Grid, GridItem } from '@bigcommerce/big-design';
import dotenv from 'dotenv'

const BASE_URL = `${process.env.REACT_APP_API_URL}`

function sortArrayOfObjects(array, key) {
    function compareObjects(a, b) {
        if (a[key] < b[key])
            return 1;
        if (a[key] > b[key])
            return -1;
        return 0;
    }

    return array.sort(compareObjects);
}

export default function OptionContracts() {
    const [positions, setPositions] = useState([])
    const [parents, setParents] = useState([])
    const [direction, setDirections] = useState('all')
    const [parent, setParent] = useState('all')
    const [dates, setDates] = useState([])
    const [expiredDate, setExpiredDate] = useState("all")
    const handleParent = (val) => setParent(val)
    const handleDirection = (val) => setDirections(val)
    const [item, setActive] = useState(0)
    const handleChange = (e, val) => {
        console.log({ e, val })
        setActive(val - 1)
    }

    const columns = [
        { field: 'id', headerName: 'id' },
        { field: 'parent', headerName: 'parent' },
        { field: 'direction', headerName: 'direction' },
        { field: 'strike_price', headerName: 'strike_price' },
        { field: 'expired_date', headerName: 'expired_date' },
        { field: 'volume', headerName: 'volume' },
        { field: 'name', headerName: 'name', width: 150 },
        { field: 'symbol', headerName: 'symbol' },

        {
            field: 'current_price',
            headerName: 'Current Price',

            type: 'number',
            editable: false,
        },
        {
            field: 'percent_change',
            headerName: 'Percent Change',

            type: 'number',
            sortable: true,
            editable: false,
        },
        {
            field: 'price_change',
            headerName: 'Price Change',

            sortable: true,
            editable: false,
        },
        {
            field: 'forecast_price',
            headerName: 'Forecast Price',
            editable: false,
        },
        {
            field: 'average_volume',
            headerName: 'Average Volume',

            type: 'number',
            editable: false,
        },
        {
            field: 'total_cost',
            headerName: 'Total Cost',

            type: 'number',
            editable: false,
        },
        {
            field: 'forecast_market_value',
            headerName: 'Forecast Market Value',

            type: 'number',
            sortable: true,
            editable: false,
        },
        {
            field: 'forecast_market_gains',
            headerName: 'Forecast Market Gains',

            type: 'number',
            sortable: true,
            editable: false,
        },
    ];


    const directions = ["all", "call", "put"]

    useEffect(() => {
        (async () => {

            const url = `${BASE_URL}/api/contracts`;
            let response = await fetch(url);
            let data = await response.json();
            const ps = data.map((x) => {
                return x.parent
            })

            const d = data.map((x) => {
                return x.expired_date
            })
            ps.push("all")
            d.push("all")
            let uniqueChars = [...new Set(ps)];
            let uniqueDates = [...new Set(d)];
            setParents(uniqueChars)

            setDates(uniqueDates.sort((a, b) => new Date(b).getTime() - new Date(a).getTime()).reverse())
        })()
    }, [])


    useEffect(() => {
        (async () => {
            let qString = expiredDate ? `parent=${parent}&direction=${direction}&expired_date=${expiredDate}` : `parent=${parent}&direction=${direction}`
            const url = `${BASE_URL}/api/contracts?${qString}`;
            let response = await fetch(url);
            let data = await response.json();

            let pos = data.map((x, index) => {
                x.id = index;
                return x
            })

            const ps = data.map((x) => {
                return x.parent
            })

            const d = data.map((x) => {
                return x.expired_date
            })
            ps.push("all")
            d.push("all")
            let uniqueChars = [...new Set(ps)];
            let uniqueDates = [...new Set(d)];
            setParents(uniqueChars)

            setDates(uniqueDates.sort((a, b) => new Date(b).getTime() - new Date(a).getTime()).reverse())

            setPositions(sortArrayOfObjects(pos, 'percent_change'))
            setActive(0)
        })()
    }, [parent, direction, expiredDate])

    return (
        <div style={{ width: '100%', paddingLeft: "1rem", paddingRight: "1rem", textAlign: 'center' }} >
            <div style={{ display: "grid" }}>
                {dates.length !== 0 ?

                    <div style={{ textAlign: 'center', display: 'inline-flex', padding: "1px", margin: 'auto' }}>
                        {dates.map((cat) => (
                            <Button
                                style={{ justifyContent: 'center', display: 'flex', padding: "1px", margin: 'auto' }}
                                variant="contained"
                                onClick={() => {
                                    setExpiredDate(cat)
                                }}>
                                {cat}
                            </Button>
                        ))}

                    </div>

                    : ''}

                {parents.length !== 0 ?

                    <div style={{ textAlign: 'center', display: 'inline-flex', padding: "1px", margin: 'auto' }}>
                        {parents.map((cat) => (
                            <Button
                                style={{ justifyContent: 'center', display: 'flex', padding: "1px", margin: 'auto' }}
                                variant="contained"
                                onClick={() => {
                                    setParent(cat)
                                }}>
                                {cat}
                            </Button>
                        ))}

                    </div>

                    : ''}
                <div style={{ textAlign: 'center', display: 'inline-flex', padding: "1px", margin: 'auto' }}>
                    {directions.map((cat) => (
                        <Button
                            style={{ justifyContent: 'center', display: 'flex', padding: "1px", margin: 'auto' }}
                            variant="contained"
                            onClick={() => {
                                setDirections(cat)
                            }}>
                            {cat}
                        </Button>
                    ))}


                </div>
            </div>
            <div>
                {positions.length !== 0 ?
                    <div>
                        <div style={{ padding: '2rem' }}>
                            <Pagination count={positions.length} variant="outlined" shape="rounded" onChange={handleChange} value={item} />
                        </div>
                        <Card id={positions[item].id} sx={{ display: 'block' }} style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="parent">
                                        {positions[item].parent}
                                    </Avatar>
                                }
                                title={positions[item].name}
                                subheader={
                                    <>
                                        <Typography>
                                            Spend ${positions[item].total_cost} on {positions[item].start_forecast_date}
                                        </Typography>

                                        <Typography>

                                            & Forecast {positions[item].forecast_market_gains > 0 ? "Gains" : "Losses"} of ${positions[item].forecast_market_gains} by {positions[item].end_forecast_date}
                                        </Typography>
                                    </>}
                            />

                            <CardContent >
                                <div style={{ textAlign: "center", height: '25%', width: '100%', display: 'block' }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            width: "100%",
                                            alignItems: 'center'
                                        }}
                                        style={{
                                            objectFit: 'contain',
                                            flexShrink: 1,
                                            alignItems: 'center',
                                            display: 'flex'
                                        }}
                                    >
                                        <Chart
                                            symbol={positions[item].symbol}
                                        />
                                    </Box>
                                </div>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    Direction
                                                </TableCell>
                                                <TableCell>
                                                    Strike Price
                                                </TableCell>
                                                <TableCell>
                                                    Expire Date
                                                </TableCell>
                                                <TableCell>
                                                    Current Price
                                                </TableCell>
                                                <TableCell>
                                                    Total Cost
                                                </TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    {positions[item].direction}
                                                </TableCell>
                                                <TableCell>
                                                    {positions[item].strike_price}
                                                </TableCell>
                                                <TableCell>
                                                    {positions[item].expired_date}
                                                </TableCell>

                                                <TableCell>
                                                    {"$" + positions[item].current_price}
                                                </TableCell>

                                                <TableCell>
                                                    {"$" + positions[item].total_cost}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    Current Date
                                                </TableCell>
                                                <TableCell>
                                                    Forecast Date
                                                </TableCell>
                                                <TableCell>
                                                    Forecast Percent Change
                                                </TableCell>
                                                <TableCell>
                                                    Forecast Price
                                                </TableCell>
                                                <TableCell>
                                                    Forecast Value
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    {positions[item].start_forecast_date}
                                                </TableCell>
                                                <TableCell>
                                                    {positions[item].end_forecast_date}
                                                </TableCell>
                                                <TableCell>
                                                    {positions[item].percent_change}
                                                </TableCell>
                                                <TableCell>
                                                    {"$" + positions[item].forecast_price}
                                                </TableCell>
                                                <TableCell>
                                                    {"$" + positions[item].forecast_market_value}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                            <div style={{ padding: '2rem' }}>
                                <Pagination count={positions.length} variant="outlined" shape="rounded" onChange={handleChange} value={item} />
                            </div>
                        </Card>

                    </div>
                    : ''}
            </div>
            <div>
                {positions.length !== 0 ?
                    <>
                        <div style={{ height: 400, width: '100%' }}>
                            <DataGrid initialState={{
                                sorting: {
                                    sortModel: [{ field: 'percent_change', sort: 'desc' }],
                                },
                            }} rows={positions} columns={columns} />

                        </div>
                    </>
                    : ''}
            </div>
        </div>

    )
}