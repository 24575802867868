/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import HomePage from "../pages/home/HomePage";
import AuthPage from "../pages/auth/AuthPage";
import ErrorsPage from "../pages/errors/ErrorsPage";
import LogoutPage from "../pages/auth/Logout";
import { LayoutContextProvider } from "../../_metronic";
import Layout from "../../_metronic/layout/Layout";
import * as routerHelpers from "../router/RouterHelpers";
import Disclaimer from '../pages/home/Disclaimer'
import { useOktaAuth } from '@okta/okta-react';
// import contactus from "../pages/home/docs/contact-us";
// import supportTerms from "../pages/home/docs/terms";
// import privacy from "../pages/home/docs/privacy"
import { toAbsoluteUrl } from "../../_metronic";

export const Routes = withRouter(({ history }) => {
  const lastLocation = useLastLocation();
  routerHelpers.saveLastLocation(lastLocation);
  const { isAuthorized, menuConfig, userLastLocation } = useSelector(
    ({ auth, urls, builder: { menuConfig } }) => ({
      menuConfig,
      isAuthorized: auth.user != null,
      userLastLocation: routerHelpers.getLastLocation()
    }),
    shallowEqual
  );

  return (
     /* Create `LayoutContext` from current `history` and `menuConfig`. */
     <LayoutContextProvider history={history} menuConfig={menuConfig}>
     <Switch>
     /* Otherwise redirect to root page (`/`) */
         <Redirect from="/auth" to={userLastLocation} />

             <Route path="/disclaimer" component={Disclaimer} />
       <Route path="/terms" component={AuthPage} />
       <Route path="/privacy" component={AuthPage} />

       <Route path="/error" component={ErrorsPage} />
       <Route path="/logout" component={LogoutPage} />

       <Layout>
           <HomePage userLastLocation={userLastLocation} />
         </Layout>

     </Switch>
   </LayoutContextProvider>
    // /* Create `LayoutContext` from current `history` and `menuConfig`. */
    // <LayoutContextProvider history={history} menuConfig={menuConfig}>
    //   <Switch>
    //     {!isAuthorized ? (
    //       /* Render auth page when user at `/auth` and not authorized. */
          
    //       <Route path="/auth/login" component={AuthPage} />
    //     ) : (
          
    //       /* Otherwise redirect to root page (`/`) */
    //       <Redirect from="/auth" to={userLastLocation} />
    //     )}
    //           <Route path="/contact" component={AuthPage} />
    //     <Route path="/terms" component={AuthPage} />
    //     <Route path="/privacy" component={AuthPage} />

    //     <Route path="/error" component={ErrorsPage} />
    //     <Route path="/logout" component={LogoutPage} />

    //     {!isAuthorized ? (
    //       /* Redirect to `/auth` when user is not authorized */
    //       <Route path="/auth" component={AuthPage} />
    //     ) : (
    //       <Layout>
    //         <HomePage userLastLocation={userLastLocation} />
    //       </Layout>
    //     )}
    //          {!isAuthorized ? <Redirect exact from="/" to="/auth" /> : (
    //       <Layout>
    //         <HomePage userLastLocation={userLastLocation} />
    //       </Layout>
    //     )}
    //   </Switch>
    // </LayoutContextProvider>
  );
});
