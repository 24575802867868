import React, { useState, useEffect } from 'react';
import { Button, Typography } from '@mui/material'
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

import { Grid, GridItem, GlobalStyles, Form, InlineMessage, Select, Input } from '@bigcommerce/big-design';
import NightsStayIcon from '@mui/icons-material/NightsStay'

import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment'


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    searchRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        opacity: 1,
        zIndex: 4,
        minHeight: '72px',

    },
    searchText: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        fontSize: '1.5rem',
        width: '100%',
        color: '#2B2C3E',
        textAlign: 'center',
        display: 'flex',
        flexWrap: 'wrap',
        borderColor: '#03DAC5',
        minHeight: '72px',
        boxShadow: '0 0 0 0.2rem #03DAC5',
        '& fieldset': {
            borderColor: '#03DAC5',
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#03DAC5',

            },
        },

    },

    content: {
        flexGrow: 1,
        height: '100%',
        overflow: 'auto',
        backgroundcolor: '#2B2C3E',
        top: '0px',
        bottom: '0px'

    },
    container: {
        paddingTop: theme.spacing(4),
        //paddingBottom: theme.spacing(4),
    },
    paper: {
        display: 'flex',
        overflow: 'auto',
        margin: 'auto',
        flexDirection: 'column',
        backgroundcolor: '#2B2C3E',
        color: '#2B2C3E',
        justifyContent: 'center',
        width: '100%',
        bordercolor: '#2B2C3E',


    },
    fixedHeight: {
        backgroundcolor: '#2B2C3E',
        color: '#2B2C3E',
        height: '100%',
    },
}));


export default function Disclaimer(props) {
    const classes = useStyles();

    return (
        <div className={classes.content} >
            <div className={classes.paper} style={{ maxWidth: '100%', margin: 'auto', display: 'flex' }}>
                <GlobalStyles />
                <Typography variant="h5" component="h5">
                    Disclaimer
               </Typography>

                <Typography>
                    This disclaimer ("Disclaimer") sets forth the general guidelines, disclosures, and terms of your use of the rocket-trend.com website ("Website"), "Rocket Trend" mobile application ("Mobile Application") and any of their related products and services (collectively, "Services"). This Disclaimer is a legally binding agreement between you ("User", "you" or "your") and this Website operator and Mobile Application developer ("Operator", "we", "us" or "our"). By accessing and using the Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Disclaimer. If you are entering into this Disclaimer on behalf of a business or other legal entity, you represent that you have the authority to bind such entity to this Disclaimer, in which case the terms "User", "you" or "your" shall refer to such entity. If you do not have such authority, or if you do not agree with the terms of this Disclaimer, you must not accept this Disclaimer and may not access and use the Services. You acknowledge that this Disclaimer is a contract between you and the Operator, even though it is electronic and is not physically signed by you, and it governs your use of the Services.
    </Typography>
                <Typography variant="h5" component="h5">
                    Representation
               </Typography>
                <Typography>
                    Any views or opinions represented on the Services are personal and belong solely to the Operator and do not represent those of people, institutions or organizations that the Operator may or may not be associated with in professional or personal capacity unless explicitly stated. Any views or opinions are not intended to malign any religion, ethnic group, club, organization, company, or individual.
                </Typography>
                <Typography variant="h5" component="h5">
                    Content and postings
               </Typography>
                <Typography>
                    You may print or copy any part of the Services for your personal or non-commercial use.
               </Typography>
                <Typography variant="h5" component="h5">
                    Not investment advice
               </Typography>
                <Typography>
                    All investments are highly speculative in nature and involve substantial risk of loss. We encourage everyone to invest very carefully. We also encourage investors to get personal advice from your professional investment advisor and to make independent investigations before acting on information found on the Services. We do not in any way whatsoever warrant or guarantee the success of any action you take in reliance on statements or information available on the Services.
                    </Typography>
                <Typography>
                    Past performance is not necessarily indicative of future results. All investments carry significant risk and all investment decisions of an individual remain the specific responsibility of that individual. There is no guarantee that systems, indicators, or signals will result in profits or that they will not result in full or partial losses. All investors are advised to fully understand all risks associated with any kind of investing they choose to do.
               </Typography>
                <Typography variant="h5" component="h5">
                    Indemnification and warranties
               </Typography>
                <Typography>
                    While we have made every attempt to ensure that the information contained on the Services is correct, the Operator is not responsible for any errors or omissions, or for the results obtained from the use of this information. All information on the Services is provided "as is", with no guarantee of completeness, accuracy, timeliness or of the results obtained from the use of this information, and without warranty of any kind, express or implied. In no event will the Operator be liable to you or anyone else for any decision made or action taken in reliance on the information on the Services, or for any consequential, special or similar damages, even if advised of the possibility of such damages. Information contained on the Services are subject to change at any time and without warning.
               </Typography>
                <Typography variant="h5" component="h5">
                    Changes and amendments
               </Typography>
                <Typography>
                    We reserve the right to modify this Disclaimer or its terms relating to the Services at any time, effective upon posting of an updated version of this Disclaimer on the Services. When we do, we will revise the updated date at the bottom of this page. Continued use of the Services after any such changes shall constitute your consent to such changes.
               </Typography>
                <Typography variant="h5" component="h5">
                    Acceptance of this disclaimer

               </Typography>
                <Typography>
                    You acknowledge that you have read this Disclaimer and agree to all its terms and conditions. By accessing and using the Services you agree to be bound by this Disclaimer. If you do not agree to abide by the terms of this Disclaimer, you are not authorized to access or use the Services.
               </Typography>
               <Typography variant="h5" component="h5">
               Contacting us
               </Typography>
               <Typography>

               If you would like to contact us to understand more about this Disclaimer or wish to contact us concerning any matter relating to it, you may send an email to hellojakesmith@icloud.com.
               </Typography>

This document was last updated on May 2, 2021
               <Typography>
               </Typography>

            </div>
        </div>

    )
}

