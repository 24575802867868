import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Pagination, PaginationItem, Box, Button, Card, CardContent, Avatar, Typography, CardHeader, Grid, Table, TableBody, TableCell, TableHead, TableRow, Paper, TableContainer, CardMedia } from '@mui/material'
import Chart from './Charts'
import BotChart from './botCharts'
import './stocks.css'
import dotenv from 'dotenv'

const BASE_URL = `${process.env.REACT_APP_API_URL}`

function sortArrayOfObjects(array, key) {
    function compareObjects(a, b) {
        if (a[key] < b[key])
            return 1;
        if (a[key] > b[key])
            return -1;
        return 0;
    }

    return array.sort(compareObjects);
}

async function getApi(url) {

    return await fetch(url, {
        method: "GET",
    })
        .then(response => response.json())
        .catch(error => console.log(error))

}

export default function CatgoryCharts() {
    const [positions, setPositions] = useState([])
    const [value, setValue] = useState({ channel: 'bot', route: 'bot' })
    const [selected, setSelected] = useState(null)
    const handleClick = (val) => setValue(val)
    const [item, setActive] = useState(0)
    const handleChange = (e, val) => {
        e.preventDefault()
        console.log({ e, val })
        setActive(val - 1)
    }

    const columns = [
        { field: 'id', headerName: 'id' },
        { field: 'name', headerName: 'name', width: 150 },
        { field: 'symbol', headerName: 'symbol' },
        {
            field: 'qty',
            headerName: 'Quantity',

            type: 'number',
            editable: false,
        },
        {
            field: 'current_price',
            headerName: 'Current Price',

            type: 'number',
            editable: false,
        },
        {
            field: 'percent_change',
            headerName: 'Percent Change',

            type: 'number',
            sortable: true,
            editable: false,
        },
        {
            field: 'price_change',
            headerName: 'Price Change',

            sortable: true,
            editable: false,
        },
        {
            field: 'forecast_price',
            headerName: 'Forecast Price',
            editable: false,
        },
        {
            field: 'average_volume',
            headerName: 'Average Volume',

            type: 'number',
            editable: false,
        },
        {
            field: 'total_cost',
            headerName: 'Total Cost',

            type: 'number',
            editable: false,
        },
        {
            field: 'forecast_market_value',
            headerName: 'Forecast Market Value',

            type: 'number',
            sortable: true,
            editable: false,
        },
        {
            field: 'forecast_market_gains',
            headerName: 'Forecast Market Gains',

            type: 'number',
            sortable: true,
            editable: false,
        },
    ];


    const cats1 = [{ channel: 'type', route: 'crypto' },
    { channel: 'bot', route: 'bot' },
    { channel: 'type', route: 'stock' },
    { channel: "category", route: "dji" },
    { channel: 'type', route: 'option' },
    { channel: 'category', route: 'oil' },
    { channel: 'category', route: 'housing' },
    { channel: 'category', route: 'aerospace' },
    { channel: 'category', route: 'ev' },
    { channel: 'category', route: 'tech' },
    ]


    useEffect(() => {
        (async () => {
            const url = value.channel === 'bot' ? `${BASE_URL}/api/bot` : `${BASE_URL}/api/category/${value.channel}/${value.route}`;

            let data = await getApi(url)
            console.log({ data })
            let pos = data.map((x, index) => {
                x.id = index;
                return x
            })

            setPositions(sortArrayOfObjects(pos, 'percent_change'))
            setActive(0)
        })()
    }, [value])

    return (
        <div style={{ width: '99%', paddingLeft: "1rem", paddingRight: "1rem", textAlign: 'center' }} >
            <div style={{ textAlign: 'center', display: 'flex', flexWrap: "wrap", padding: "1px", margin: 'auto' }}>
                {cats1.map((cat, index) => (
                    <Button
                        key={index}
                        style={{ justifyContent: 'center', display: 'flex', padding: "1px", margin: 'auto' }}
                        variant="contained"
                        onClick={(e) => {
                            e.preventDefault()
                            setValue(cat)
                        }}>
                        {cat.route}
                    </Button>
                ))}

            </div>
            <div>
                {positions.length !== 0 ?
                    <div>
                        <div style={{ padding: '2rem' }}>
                            <Pagination count={positions.length} variant="outlined" shape="rounded" onChange={handleChange} value={item} />
                        </div>
                        <Card id={positions[item].id} sx={{ display: 'block' }} style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="symbol">
                                        {positions[item].symbol}
                                    </Avatar>
                                }
                                title={positions[item].name}
                                subheader={
                                    <>
                                        <Typography>
                                            Spend ${positions[item].total_cost} for {parseInt(positions[item].qty)} shares on {positions[item].start_forecast_date}
                                        </Typography>

                                        <Typography>

                                            & Forecast {positions[item].forecast_market_gains > 0 ? "Gains" : "Losses"} of ${positions[item].forecast_market_gains} by {positions[item].end_forecast_date}
                                        </Typography>
                                    </>}
                            />

                            <CardContent >
                                <div style={{ textAlign: "center", height: '25%', width: '100%', display: 'block' }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            width: "100%",
                                            alignItems: 'center'
                                        }}
                                        style={{
                                            objectFit: 'contain',
                                            flexShrink: 1,
                                            alignItems: 'center',
                                            display: 'flex'
                                        }}
                                    >
                                        {value.channel === "bot" ? <BotChart
                                            symbol={positions[item].symbol}
                                        /> :
                                            <Chart
                                                symbol={positions[item].symbol}
                                            />
                                        }

                                    </Box>
                                </div>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    Current Date
                                                </TableCell>
                                                <TableCell>
                                                    Current Price
                                                </TableCell>
                                                <TableCell>
                                                    Total Cost
                                                </TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    {positions[item].start_forecast_date}
                                                </TableCell>
                                                <TableCell>
                                                    {"$" + positions[item].current_price}
                                                </TableCell>

                                                <TableCell>
                                                    {"$" + positions[item].total_cost}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    Forecast Date
                                                </TableCell>
                                                <TableCell>
                                                    Forecast Percent Change
                                                </TableCell>
                                                <TableCell>
                                                    Forecast Price
                                                </TableCell>
                                                <TableCell>
                                                    Forecast Value
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    {positions[item].end_forecast_date}
                                                </TableCell>
                                                <TableCell>
                                                    {positions[item].percent_change}
                                                </TableCell>
                                                <TableCell>
                                                    {"$" + positions[item].forecast_price}
                                                </TableCell>
                                                <TableCell>
                                                    {"$" + positions[item].forecast_market_value}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                            <div style={{ padding: '2rem' }}>
                                <Pagination count={positions.length} variant="outlined" shape="rounded" onChange={handleChange} value={item} />
                            </div>
                        </Card>

                    </div>
                    : ''}
            </div>
            <div>
                {positions.length !== 0 ?
                    <>
                        <div style={{ height: 400, width: '100%' }}>
                            <DataGrid initialState={{
                                sorting: {
                                    sortModel: [{ field: 'percent_change', sort: 'desc' }],
                                },
                            }} rows={positions} columns={columns}
                                pageSize={25}
                                rowsPerPageOptions={[25]} />

                        </div>
                    </>
                    : ''}
            </div>
        </div>

    )
}