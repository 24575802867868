import React, { useState, useEffect } from 'react';

import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function Spinner(props){

    const [spin, setSpin] = useState(0)
    useEffect(() => {

        setSpin(props.spin)

    }, [props]);

    return (
        <>
        {spin === 1 ?
        <Loader
          type="ThreeDots"
          color="#00BFFF"
          height={80}
          width={80}
        />
        : ''}
        </>
      );
}

export default Spinner;