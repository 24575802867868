import React, { useState, useEffect } from 'react';
import { Pagination, PaginationItem, Box, Button, Card, CardContent, Avatar, Typography, CardHeader, Grid, Table, TableBody, TableHead, TableRow, Paper, TableContainer, CardMedia, Tab } from '@mui/material'
import TableCell from '@mui/material/TableCell';
import { DataGrid } from '@mui/x-data-grid';


import Chart from './Charts'

import './stocks.css'
import dotenv from 'dotenv'
import queryString from 'query-string'


const BASE_URL = `${process.env.REACT_APP_API_URL}`

function sortArrayOfObjects(array, key) {
    function compareObjects(a, b) {
        if (parseInt(a[key]) < parseInt(b[key]))
            return 1;
        if (parseInt(a[key]) > parseInt(b[key]))
            return -1;
        return 0;
    }

    return array.sort(compareObjects);
}



export default function StockChart() {
    const [positions, setPositions] = useState([])
    const [value, setValue] = React.useState(null);
    const handleClick = (val) => setValue(val)
    const [item, setActive] = useState(0)
    const handleChange = (e, val) => {
        console.log({ e, val })
        setActive(val - 1)
    }


    const columns = [
        { field: 'id', headerName: 'id', width: 90 },
        { field: 'name', headerName: 'name', width: 150 },
        { field: 'symbol', headerName: 'symbol', width: 90 },
        {
            field: 'qty',
            headerName: 'Quantity',
            width: 90,
            type: 'number',
            editable: false,
        },
        {
            field: 'current_price',
            headerName: 'Current Price',
            width: 90,
            type: 'number',
            editable: false,
        },
        {
            field: 'percent_change',
            headerName: 'Percent Change',
            width: 90,
            type: 'number',
            sortable: true,
            editable: false,
        },
        {
            field: 'price_change',
            headerName: 'Price Change',
            width: 90,
            sortable: true,
            editable: false,
        },
        {
            field: 'forecast_price',
            headerName: 'Forecast Price',
            width: 90,
            editable: false,
        },
        {
            field: 'average_volume',
            headerName: 'Average Volume',
            width: 90,
            type: 'number',
            editable: false,
        },
        {
            field: 'total_cost',
            headerName: 'Total Cost',
            width: 90,
            type: 'number',
            editable: false,
        },
        {
            field: 'forecast_market_value',
            headerName: 'Forecast Market Value',
            width: 90,
            type: 'number',
            sortable: true,
            editable: false,
        },
        {
            field: 'forecast_market_gains',
            headerName: 'Forecast Market Gains',
            width: 90,
            type: 'number',
            sortable: true,
            editable: false,
        },
        {
            field: 'start_forecast_date',
            headerName: 'Start Date',
            width: 90,
            sortable: true,
            editable: false,
        },
        {
            field: 'end_forecast_date',
            headerName: 'End Date',
            width: 90,
            sortable: true,
            editable: false,
        },
    ];


    useEffect(() => {
        let search = queryString.parse(window.location.search);
        let symbol = search.symbol ? search.symbol : null;

        (async () => {

            let url = symbol ? `${BASE_URL}/api/watchlist-stocks/${symbol}` : `${BASE_URL}/api/watchlist-stocks`


            let response = await fetch(url);
            let data = await response.json();

            setPositions(data)
        })()
    }, [window.location, value])



    return (

        <div style={{ width: '100%', paddingLeft: 0, paddingRight: 0, textAlign: 'center' }} >

            <div>
                {positions.length !== 0 ?
                    <>
                        <div style={{ height: 400, width: '100%' }}>
                            <DataGrid initialState={{
                                sorting: {
                                    sortModel: [{ field: 'percent_change', sort: 'desc' }],
                                },
                            }} rows={positions} columns={columns}
                                pageSize={25}
                                rowsPerPageOptions={[25]} />

                        </div>
                    </>
                    : ''}

            </div>
        </div>

    )
}