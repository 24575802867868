export default {
    header: {
      self: {},
      items: [
        {
          title: "Rocket Trend",
          root: true,
          alignment: "left",
          page: "home",
          icon: "flaticon-star"
        }
      ]
    }
  };
  