import React, { useState, useEffect } from 'react';
import Chart from './Charts'
import {TabPanel, Tabs, Tab, Box, Paper, Button, Typography, MenuList, MenuItem, FormControlLabel, Checkbox, Radio, RadioGroup } from '@mui/material'
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

import { Grid, GridItem, GlobalStyles, Form, InlineMessage, Select, Input, Search, FormGroup, Table, Panel, Text } from '@bigcommerce/big-design';


import './stocks.css'
import dotenv from 'dotenv'

const BASE_URL = `${process.env.REACT_APP_API_URL}`

export default function Positions() {
    const [positions, setPositions] = useState([])
    const [isLocked, setLock] = useState(true)
    const [passwordVal, setPasswordVal] = useState('');
    const handlePasswordChange = (event) => setPasswordVal(event.target.value);
    const handlePasswordValidate = () => {
        if (passwordVal === "rocketTrend!") {
            setLock(false)
        } else {
            setLock(true)
        }

    }

    useEffect(() => {
        if (!isLocked) {

        (async () => {
            const url = `${BASE_URL}/webull/saved/positions`;
            let response = await fetch(url);
            let data = await response.json();
            let pos = []
            
            let toalCost = 0
            let marketVal = 0
            let totalGains = 0
            let foreCastGains = 0
            let foreCastMktVal = 0
            let forecastTotalGains = 0
            //   pos.push({
            //     name: "TOTALS", symbol: null, qty: null, total_cost: toalCost, market_value: marketVal, gains: totalGains, forcast_gains: foreCastGains, forecast_market_value: foreCastMktVal
            //   })

            Promise.all(data.map(x => {
                toalCost += parseInt(x.total_cost)
                marketVal += parseInt(x.market_value)
                totalGains += parseInt(x.gains)
                foreCastGains += parseInt(x.forcast_gains)
                foreCastMktVal += parseInt(x.forecast_market_value)
                forecastTotalGains += parseInt(x.forecast_total_gains)
                pos.unshift(x)
            }))

            pos.push({
                "ask_price": "-",
                "buy_price": "-",
                "cost_price": "-",
                "current_price": "-",
                "forecast_total_gains": forecastTotalGains,
                "forcast_gains":foreCastGains,
                "forecast_market_value": foreCastMktVal,
                "forecast_price": "-",
                "gains": totalGains,
                "last_price": "-",
                "market_value": marketVal,
                "name": "TOTALS",
                "qty": "-",
                "symbol": "-",
                "total_cost": toalCost,
                "webull":  "-"

            })


            setPositions(pos)
        })()
    }
    }, [isLocked])

    return (
            <positions>
            {isLocked ?
                <Panel
                    header="This website is password protected. Use the password to enter the website."
                >
                    <Text>
                        This website is password protected. Use the password to enter the website.
                    </Text>
                    <Input
                        label="Enter website password"
                        type="text"
                        value={passwordVal}
                        onChange={handlePasswordChange}
                        required
                    />
                    <div style={{ textAlign: 'center' }}>
                        <Button variant="contained" size={"large"} color="secondary" onClick={handlePasswordValidate}>
                            Enter
                        </Button>
                    </div>
                </Panel>
                : 
                <div>
                {positions.length !== 0 ?
                        <Table
                            columns={[
                                { header: 'Name', hash: 'name', tooltip: 'Header tooltip', render: ({ name }) => name },
                                { header: 'Symbol', hash: 'symbol', tooltip: 'Header tooltip', render: ({ symbol }) => symbol },
                                { header: 'QTY', hash: 'qty', render: ({ qty }) => qty },
                                { header: 'Cost Price', hash: 'cost_price', render: ({ cost_price }) => cost_price },
                                { header: 'Current Price', hash: 'current_price', render: ({ current_price }) => current_price },
                                { header: 'Current Gains', hash: 'gains', render: ({ gains }) => gains },
                                { header: 'Total Cost', hash: 'total_cost', render: ({ total_cost }) => total_cost },
                                { header: 'Market Value', hash: 'market_value', render: ({ market_value }) => market_value },
                                { header: 'Forecast Price', hash: 'forecast_price', render: ({ forecast_price }) => forecast_price },
                                { header: 'Forecast Total Gains', hash: 'forcast_gains', render: ({ forecast_total_gains }) => forecast_total_gains },
                                { header: 'Forecast Mkt Value Gains', hash: 'forcast_gains', render: ({ forcast_gains }) => forcast_gains },
                                { header: 'Forecast Market Value', hash: 'forecast_market_value', render: ({ forecast_market_value }) => forecast_market_value },
                                { header: 'Last Price', hash: 'last_price', render: ({ last_price }) => last_price },

                            ]}
                            items={positions}
                            stickyHeader
                        />
                    : ''}
                    </div>
                }
              </positions>

)
                }