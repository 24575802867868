import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";
import Stocks from './stocks'
import Menu from './Menu'
import Positions from './Positions'
import StockCharts from './StockChart'


export default function HomePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /quick-start. */
          <Redirect exact from="/" to={`/home`} />
        }
        {/* <Route path={`/jake`} component={Positions} style={{backgroundColor: '#000'}} /> */}
        <Route path={`/home`} component={Menu} style={{backgroundColor: '#000', paddingLeft: 0, paddingRight: 0}} />
        {/* <Route path={`/stock`} component={StockCharts} style={{backgroundColor: '#000', paddingLeft: 0, paddingRight: 0}} /> */}
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
