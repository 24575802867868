import React, { useState, useEffect } from 'react';
import Chart from './Charts'
import { Pagination, PaginationItem, Box, Button, Card, CardContent, Avatar, Typography, CardHeader, Table, TableBody, TableCell, TableHead, TableRow, Paper, TableContainer, CardMedia, Tab } from '@mui/material'
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

import { Grid, GridItem, GlobalStyles, Form, InlineMessage, Select, Input, Search, FormGroup, Panel, Text } from '@bigcommerce/big-design';
import queryString from 'query-string'
import Check from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment'
import Spinner from './Spinner'
import Disclaimer from './Disclaimer'
import './stocks.css'
import dotenv from 'dotenv'
import { DataGrid } from '@mui/x-data-grid';


// import openSocket from 'socket.io-client'

dotenv.config()

const BASE_URL = `${process.env.REACT_APP_API_URL}`

const columns = [
    { field: 'id', headerName: 'id' },
    { field: 'name', headerName: 'name', width: 150 },
    { field: 'symbol', headerName: 'symbol' },
    {
        field: 'qty',
        headerName: 'Quantity',

        type: 'number',
        editable: false,
    },
    {
        field: 'current_price',
        headerName: 'Current Price',

        type: 'number',
        editable: false,
    },
    {
        field: 'percent_change',
        headerName: 'Percent Change',

        type: 'number',
        sortable: true,
        editable: false,
    },
    {
        field: 'price_change',
        headerName: 'Price Change',

        sortable: true,
        editable: false,
    },
    {
        field: 'forecast_price',
        headerName: 'Forecast Price',
        editable: false,
    },
    {
        field: 'average_volume',
        headerName: 'Average Volume',

        type: 'number',
        editable: false,
    },
    {
        field: 'total_cost',
        headerName: 'Total Cost',

        type: 'number',
        editable: false,
    },
    {
        field: 'forecast_market_value',
        headerName: 'Forecast Market Value',

        type: 'number',
        sortable: true,
        editable: false,
    },
    {
        field: 'forecast_market_gains',
        headerName: 'Forecast Market Gains',

        type: 'number',
        sortable: true,
        editable: false,
    },
];

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    searchRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        opacity: 1,
        zIndex: 4,
        minHeight: '72px',

    },
    searchText: {
        marginLeft: "1rem",
        marginRight: "1rem",
        fontSize: '1.5rem',
        width: '100%',
        color: '#2B2C3E',
        textAlign: 'center',
        display: 'flex',
        flexWrap: 'wrap',
        borderColor: '#03DAC5',
        minHeight: '72px',
        boxShadow: '0 0 0 0.2rem #03DAC5',
        '& fieldset': {
            borderColor: '#03DAC5',
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#03DAC5',

            },
        },

    },

    content: {
        flexGrow: 1,
        height: '100%',
        overflow: 'auto',
        backgroundcolor: '#2B2C3E',
        top: '0px',
        bottom: '0px'

    },
    container: {
        paddingTop: "4rem",
        //paddingBottom: theme.spacing(4),
    },
    paper: {
        display: 'flex',
        overflow: 'auto',
        margin: 'auto',
        flexDirection: 'column',
        backgroundcolor: '#2B2C3E',
        color: '#2B2C3E',
        justifyContent: 'center',
        width: '100%',
        bordercolor: '#2B2C3E',


    },
    fixedHeight: {
        backgroundcolor: '#2B2C3E',
        color: '#2B2C3E',
        height: '100%',
    },
}));


function Stocks() {
    const classes = useStyles();
    const [forecast, setForecast] = useState([])
    const [data, setData] = useState([])
    const [value, setValue] = useState(null);
    const [score, setScore] = useState(0)
    const [average, setAverage] = useState(0)
    const [searchVal, setSearchval] = useState(null)
    const [loading, setIsLoading] = useState(0)
    const handleSearchChange = (val) => setSearchval(val);
    const [current_price, setcurrent_price] = useState({})
    const [type, setType] = useState(null)
    const [symbols, setSymbols] = useState([])
    const [positions, setPositions] = useState([])
    const [item, setActive] = useState(0)
    const handleChange = (e, val) => {
        console.log({ e, val })
        setActive(val - 1)
    }
    const [tab, setTab] = useState('')



    const handleSearch = async (event) => {

        setSearchval(event.target.value);

        // setValue(event.target.value)
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        setValue(searchVal);
    }


    useEffect(() => {
        let search = queryString.parse(window.location.search);

        let symbol = value ? value.toLowerCase() : !value && search.symbol ? search.symbol : null;

        (async () => {

            let url = `${BASE_URL}/api/category/symbol/${symbol}`


            let response = await fetch(url);
            let data = await response.json();
            let pos = data.map((x, index) => {
                x.id = index;
                return x
            })

            setPositions(pos)
        })()
    }, [window.location, value])



    return (
        <div className={classes.content} >

            <GlobalStyles />
            <stocks>
                <div className={classes.paper} style={{ textAlign: 'center', justifyContent: 'center', maxWidth: '100%', margin: 'auto', display: 'flex' }}>
                    <Grid>
                        <GridItem>

                            <Typography variant="h5" component="h5">
                                Search Stock Trend..
                            </Typography>
                        </GridItem>

                        <GridItem>
                            <Form id="rocket" fullWidth>
                                <Select
                                    id={'select'}
                                    filterable={true}
                                    maxHeight={300}
                                    maxWidth={'100%'}
                                    // onSubmit={e => handleSubmit(e)}
                                    onInput={e => handleSearch(e)}
                                    onOptionChange={e => handleSearch(e)}
                                    options={symbols}
                                    placeholder={searchVal}
                                    placement={'bottom-start'}
                                    required
                                    value={value}
                                />
                            </Form>

                        </GridItem>

                        <GridItem>
                            <>
                                {loading === 0 ?
                                    <Button variant="contained" color="secondary" onClick={handleSubmit}>
                                        Find Trends <SearchIcon />
                                    </Button>
                                    : <Button disabled variant="contained" color="secondary" onClick={handleSubmit}>
                                        Find Trends <SearchIcon />
                                    </Button>}
                            </>
                        </GridItem>

                    </Grid>
                    {positions.length !== 0 ?
                    <div>
                        <div style={{ padding: '2rem' }}>
                            <Pagination count={positions.length} variant="outlined" shape="rounded" onChange={handleChange} value={item} />
                        </div>
                        <Card id={positions[item].id} sx={{ display: 'block' }} style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="symbol">
                                        {positions[item].symbol}
                                    </Avatar>
                                }
                                title={positions[item].name}
                                subheader={
                                    <>
                                        <Typography>
                                            Spend ${positions[item].total_cost} for {parseInt(positions[item].qty)} shares on {positions[item].start_forecast_date}
                                        </Typography>

                                        <Typography>

                                            & Forecast {positions[item].forecast_market_gains > 0 ? "Gains" : "Losses"} of ${positions[item].forecast_market_gains} by {positions[item].end_forecast_date}
                                        </Typography>
                                    </>}
                            />

                            <CardContent >
                                <div style={{ textAlign: "center", height: '25%', width: '100%', display: 'block' }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            width: "100%",
                                            alignItems: 'center'
                                        }}
                                        style={{
                                            objectFit: 'contain',
                                            flexShrink: 1,
                                            alignItems: 'center',
                                            display: 'flex'
                                        }}
                                    >
                                        <Chart
                                            symbol={positions[item].symbol}
                                        />
                                    </Box>
                                </div>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    Current Date
                                                </TableCell>
                                                <TableCell>
                                                    Current Price
                                                </TableCell>
                                                <TableCell>
                                                    Total Cost
                                                </TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    {positions[item].start_forecast_date}
                                                </TableCell>
                                                <TableCell>
                                                    {"$" + positions[item].current_price}
                                                </TableCell>

                                                <TableCell>
                                                    {"$" + positions[item].total_cost}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    Forecast Date
                                                </TableCell>
                                                <TableCell>
                                                    Forecast Percent Change
                                                </TableCell>
                                                <TableCell>
                                                    Forecast Price
                                                </TableCell>
                                                <TableCell>
                                                    Forecast Value
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    {positions[item].end_forecast_date}
                                                </TableCell>
                                                <TableCell>
                                                    {positions[item].percent_change}
                                                </TableCell>
                                                <TableCell>
                                                    {"$" + positions[item].forecast_price}
                                                </TableCell>
                                                <TableCell>
                                                    {"$" + positions[item].forecast_market_value}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                            <div style={{ padding: '2rem' }}>
                                <Pagination count={positions.length} variant="outlined" shape="rounded" onChange={handleChange} value={item} />
                            </div>
                        </Card>

                    </div>
                    : ''}
            </div>
            <div>
                {positions.length !== 0 ?
                    <>
                        <div style={{ height: 400, width: '100%' }}>
                            <DataGrid initialState={{
                                sorting: {
                                    sortModel: [{ field: 'percent_change', sort: 'desc' }],
                                },
                            }} rows={positions} columns={columns}
                                pageSize={25}
                                rowsPerPageOptions={[25]} />

                        </div>
                    </>
                    : ''}
                </div>
            </stocks>


        </div >
    );
}

export default Stocks;
