import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Stocks from './stocks'
import StockCharts from './StockChart';
import CryptoCharts from './Crypto Charts'
import CatgoryCharts from './CatgoryCharts'
import OptionContracts from './OptionContracts'
// import Positions from './Positions'
import queryString from 'query-string'
export default function Menu() {
  const search = queryString.parse(window.location.search);
  const [value, setValue] = React.useState(search.symbol ? '1' : '4');


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <Box sx={{ width: '100%', typography: 'body1', paddingLeft: 0, paddingRight: 0 }}>

      <TabContext value={value} style={{ paddingLeft: 0, paddingRight: 0 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example" style={{ justifyContent: 'space-around', display: 'flex' }}>
            <Tab label="Stock Trends" value="1" />
             {/* <Tab label="Stocks Chart" value="2" /> */}
            {/* <Tab label="Option Contracts" value="3" /> */}
            {/* <Tab label="Category Charts" value="4" /> */}
            {/* <Tab label="Positions" value="5" /> */}
          </TabList>
        </Box>
        <TabPanel value="1"><Stocks /></TabPanel>
        {/* <TabPanel value="2"><StockCharts/></TabPanel> */}
        {/* <TabPanel value="3"><OptionContracts/></TabPanel> */}
       {/* <TabPanel value="3"><CryptoCharts /></TabPanel> */}
        {/* <TabPanel value="4" style={{ paddingLeft: 0, paddingRight: 0 }}><CatgoryCharts /></TabPanel> */}
        {/* <TabPanel value="5"><Positions /></TabPanel> */}
      </TabContext>
    </Box>
  );
}